<template>
  <div class="home">
    <div class="left">
      <left-nav></left-nav>
    </div>
    <div class="right">
      <top-header></top-header>
      <div class="main" :style="{'min-width': isMobile ? '960px' : '1160px'}">
        <div class="page-area">
          <transition name="fade-transform" mode="out-in">
            <!-- <keep-alive> -->
            <router-view :key="$route.fullPath" />
            <!-- </keep-alive> -->
          </transition>
        </div>
      </div>
    </div>
    <div class="call-short" v-if="saleType">
      <img
        class="call-img"
        :src="require('@/assets/image/home/call-icon-1.png')"
        alt=""
        title="点击显示电话浮框"
        @click="handleCallIconClick"
      />
    </div>
    <staffReceiveCallDialog v-if="saleType == 1" />
    <parttimeReceiveCallDialog v-else-if="saleType == 2" />
  </div>
</template>

<script>
import topHeader from "@/components/layout/topHeader";
import leftNav from "@/components/layout/leftNav";
import { mapMutations, mapState } from "vuex";
const dayjs = require("dayjs");
const duration = require("dayjs/plugin/duration");
dayjs.extend(duration);
import { getTimeTotal, dealHandCallUserfield, isMobile } from "@/utils/tool";
import parttimeReceiveCallDialog from "@/components/sales/parttimeReceiveCallDialog";
import staffReceiveCallDialog from "@/components/sales/staffReceiveCallDialog";
import { getCallData } from "@/config/callData";
import { apiSaveSaleCallRecord, apiCallSysAddBlack, apiGetUserSeasNum } from "@/api/sales";
import { socketUrl, pathName, envType } from "@/config/index";
import { apiApiDataSendServe } from "@/api/index";
const defaultLastHandBusyType = 1;
const { io } = require("socket.io-client");
export default {
  data() {
    return {
      isMobile: isMobile(),
      isSalesImporter: false,
      saleType: null, //saleType  1 销售专员 2 销售兼职
      dkyAgentType: 1, // 1 启用 2 禁用
      newAddUseNum: 0,
      newHighSeasNum: sessionStorage.getItem("highSeasNum"),
      callInfo: {
        transferType: 2,  // 1 盲转 2 非盲转
        isAllocDkyAccount: true,
        saleType: "",
        dkyAgentAccount: "", // 点控云坐席账号
        dkyAgentPassword: "", // 点控云坐席密码
        dkyDeviceAccount: "", // 点控云分机账号
        dkyDevicePassword: "", // 点控云分机密码
        dkyOrganId: "", // 点控云机构id
        token: "2DD3E4675E4AD7C21018583089C4DD06",
        Host: "ctr1.diankongcloud.cn",
        WSPort: "8089",
        agentHost: "calltest.diankongcloud.cn",
        agentPort: "8310",
        queue: "", // 10037001001153  70000001128935
        //queue: "70000001128937",
        msg: "",
        callStatus: 0, // 0 默认状态 1 呼叫中 2 响铃 3 通话中 4 通话已结束 5 来电响铃 6 来电响铃-点击接听  7 刚点击手动拨号
        handCallStatus: 0, // 0 默认状态 1 呼叫中 2 响铃 3 通话中 4 通话已结束 5 来电响铃 6 来电响铃-点击接听  7 刚点击手动拨号  8 坐席挂断  9 用户挂断  10 用户未接 11 已挂断（可能是号码拨打完情况）
        changeCallStatus: 0, // 主动转接参数 0 默认状态  1 转接中 2 转接成功 3 转接失败 4 结束
        blindferCallStatus: 0, // 盲转参数 0 默认状态 1 转接中 2 转接成功 3 转接失败
        isCallLogin: false,
        isPause: false,
        isMute: false,
        isBusy: false, // 真实置忙置闲
        _lastHandBusyType: defaultLastHandBusyType, // 手动点击置忙置闲状态(及对应开启和暂停按钮)
        _lastAutoBusyType: 0, // 程序根据是否填写客户类别自动置忙置闲
        _isHandHangUp: false, // 手动拨打时，是否是自己点的挂断
        callType: 1, // 1 预测外呼分配 2 转接 3 手动拨打
        handCallType: 1, // 1 新数据列表 2 真实数据列表
        isFromHandCall: false, // 是否来自手拨列表中的拨打电话
        linkedid: "", // 共用linkedid
        handCallLinkedid: "", // 手动拨打linkedid
        autoCallLinkedid: "", // 兼职预测外呼linkedid
        transforLinkedid: "", // 兼职转接给专员后专员linkedid
        duration: "",
        handDuration: "",
        studentId: "",
        ext2: "", // 转接销售分机号
        _handCallStudentId: null,
        isHandCallBlack: false,
        _continueOnTime: 0,
        continueOnTime: "00:00:00",
        _continueOffTime: 0,
        continueOffTime: "00:00:00",
      },
      sysCallInfo: {
        flagOn: false, // 真实系统设置当前能不能拨打电话
        causeTxt: null, // 原因
      },
      socket: null,
    };
  },
  computed: {
    ...mapState(["navIsOpen"]),
    ...mapState("dialogCont", ["dialogCallInfo"]),
    ...mapState("callInfo", ["sysStatus"]),
  },
  watch: {
    "callInfo.isCallLogin"(isCallLogin) {
      console.log("isCallLogin: ", isCallLogin);
      if (isCallLogin) {
        this.handleContinueTimeCompute();
      }
    },
    sysStatus: {
      handler() {
        if (!this.saleType) {
          return;
        }
        console.log("sysStatus: ", JSON.parse(JSON.stringify(this.sysStatus)));
        clearTimeout(this._sysCallTimer);
        this._sysCallTimer = setTimeout(() => {
          this.handleSysCallStatus();
        }, 500);
      },
      deep: true,
    },
    "sysCallInfo.flagOn"(flagOn) {
      if (this.saleType && this.callInfo.isAllocDkyAccount && this.dkyAgentType != 2) {
        if (flagOn) {
          if (envType == "local") {
            // 电话功能
            this.initData();
          } else {
            this.initData();
          }
        } else {
          this.msgWarn(this.sysCallInfo.causeTxt);
          clearTimeout(this._sysCallLogoutTimer);
          this._sysCallLogoutTimer = setTimeout(() => {
            this.handleTryLogout();
          }, 500);
        }
      }
    },
  },
  components: {
    topHeader,
    leftNav,
    parttimeReceiveCallDialog,
    staffReceiveCallDialog,
  },
  provide() {
    return {
      main: this,
    };
  },
  created() {
    console.log("home created");
    this.dkyAgentType = sessionStorage.getItem("dkyAgentType");
    this.callInfo.dkyAgentAccount = sessionStorage.getItem("dkyAgentAccount");
    this.callInfo.dkyAgentPassword = sessionStorage.getItem("dkyAgentPassword");
    this.callInfo.dkyDeviceAccount = sessionStorage.getItem("dkyDeviceAccount");
    this.callInfo.dkyDevicePassword = sessionStorage.getItem("dkyDevicePassword");
    this.callInfo.dkyOrganId = sessionStorage.getItem("dkyOrganId");
    this.callInfo.isAllocDkyAccount = !!(
      this.callInfo.dkyAgentAccount &&
      this.callInfo.dkyAgentPassword &&
      this.callInfo.dkyDeviceAccount &&
      this.callInfo.dkyDevicePassword &&
      this.callInfo.dkyOrganId
    );
    const queueNumber = sessionStorage.getItem("queueNumber")
      ? sessionStorage.getItem("queueNumber")
      : null;
    let saleType = sessionStorage.getItem("saleType");
    if (saleType == 1) {
      this.callInfo.queue = null;
      if (sessionStorage.getItem("useCallPlatform") == 0) {
        this.showConfirmInfo("当前用户没有分配电话服务，不能拨打电话！");
        return;
      }
      if (!this.callInfo.isAllocDkyAccount) {
        this.showConfirmInfo("该销售角色账号，没有分配坐席，不能用电话功能！");
      }
    } else if (saleType == 2) {
      this.callInfo.queue = queueNumber;
      if (sessionStorage.getItem("useCallPlatform") == 0) {
        this.showConfirmInfo("当前用户没有分配电话服务，不能拨打电话！");
        return;
      }
      if (!this.callInfo.isAllocDkyAccount) {
        this.showConfirmInfo("该销售角色账号，没有分配坐席，不能用电话功能！");
      } else if (!queueNumber) {
        /* this.showConfirmInfo(
          "该兼职角色账号没有配置任务队列，将不会收到预测外呼电话，只能用于手动拨打！"
        ); */
      }
    }
    // 暂时设定为1,测试专员
    this.saleType = saleType;
    this.isSalesImporter = !!sessionStorage.getItem("salesImporter");
    if (this.saleType || this.isSalesImporter) {
      this.initSocket();
    }
    this.sysCallInfo.flagOn = false;
    this.sysCallInfo.causeTxt = null;
    this.sysStatusChange({
      status: 1, // 1 开启 2 关闭
      startTime: null,
      endTime: null,
      dataFlag: false, // 数据已获取
    });
    this.handleStartCheckSysCallStatus();
  },
  mounted() {},
  beforeDestroy() {
    this.handleUserLogout();
    this.handleLogoutSocket();
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  methods: {
    ...mapMutations(["updateAuthorities"]),
    ...mapMutations("dialogCont", ["dialogCallInfoChange"]),
    ...mapMutations("callInfo", ["sysStatusChange"]),
    setCurUseCallPlatform(curUseCallPlatform) {
      if(curUseCallPlatform != sessionStorage.getItem("useCallPlatform")){
        apiApiDataSendServe({
          type: "call-agent-change",
          userId: sessionStorage.getItem("userId"),
          oldUseCallPlatform: sessionStorage.getItem("useCallPlatform"),
          newUseCallPlatform: curUseCallPlatform,
        })
        this.$confirm("坐席状态发生了改变，请退出重新登录?", "提示", {
          closeOnClickModal: false,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            });
            setTimeout(() => {
              location.reload()
            }, 1000)
          })
          .catch(() => {});
      }
    },
    setCurDkyAgentAccount(curDkyAgentAccount) {
      if (
        (this.callInfo.dkyDeviceAccount || curDkyAgentAccount) &&
        this.callInfo.dkyDeviceAccount != curDkyAgentAccount
      ) {
        apiApiDataSendServe({
          type: "call-agent-change",
          userId: sessionStorage.getItem("userId"),
          oldDkyDeviceAccount: this.callInfo.dkyDeviceAccount,
          newDkyDeviceAccount: curDkyAgentAccount,
        })
        this.$confirm("坐席状态发生了改变，请退出重新登录?", "提示", {
          closeOnClickModal: false,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$router.push({
              path: "/login",
            });
            setTimeout(() => {
              location.reload()
            }, 1000)
          })
          .catch(() => {});
      }
    },
    /* 获取公海新数据数量 */
    async getNewHighSeasNum() {
      try{
        const resData = await apiGetUserSeasNum({userId: sessionStorage.getItem("userId")})
        this.newHighSeasNum = resData
      }catch(err){
        let msg = err.msg ? err.msg : '获取公海新数据数量发生异常！'
        this.msgError(msg)
        console.error("apiGetUserSeasNum err: ", err)
      }
    },
    /* 测试代码 */
    handleTest() {
      if(process.env.VUE_APP_SERVER_ENV == 'dev'){
        this.callInfo.studentId = 23273
        this.callInfo.callStatus = 5;
        this.callInfo.callType = 2;
        this.callInfo.linkedid = null;
      }
    },
    /* 保存电话数据 */
    handleSaveCallData(data) {
      /* if (this.socket && envType != "env") {
        this.socket.emit("callDataChange", data);
      } */
      let studentId = this.callInfo._handCallStudentId ? this.callInfo._handCallStudentId : this.callInfo.studentId
      this.socket.emit("callDataChange", { ...data, studentId,  callPlatform: 1});
    },
    /* 添加运营商电话黑名单 */
    async handleCallSysAddBlack(studentId) {
      try {
        await apiCallSysAddBlack({
          studentId: studentId ? studentId : this.callInfo._handCallStudentId,
        });
        this.msgSuccess("该手机号已添加到系统黑名单！请拨打下一个吧！");
      } catch (err) {
        let msg = err.msg ? err.msg : "添加黑名单发生异常！";
        this.msgError(msg);
        console.error("apiCallSysAddBlack err: ", err);
      }
    },
    /* 保存手拨通话记录 */
    async handleSaveCallRecord() {
      try {
        if (!this.callInfo.isFromHandCall) {
          return;
        }
        const agent =
          sessionStorage.getItem("dkyAgentAccount") ||
          localStorage.getItem("dkyAgentAccount");
        const saveData = {
          studentId: this.callInfo._handCallStudentId,
          linkedid: this.callInfo.linkedid,
          userId: sessionStorage.getItem("userId"),
          type: this.callInfo.handCallType,
          agent,
        };
        await apiSaveSaleCallRecord(saveData);
      } catch (err) {
        let msg = err.msg ? err.msg : "保存通话记录发生异常！";
        this.msgError(msg);
        console.error("apiSaveSaleCallRecord err: ", err);
      }
    },
    /* 保存预测外呼通话记录 */
    async handleSaveAutoCallRecord(linkedid, type) {
      try {
        const agent =
          sessionStorage.getItem("dkyAgentAccount") ||
          localStorage.getItem("dkyAgentAccount");
        const saveData = {
          studentId: this.callInfo.studentId,
          linkedid,
          userId: sessionStorage.getItem("userId"),
          type,
          agent,
        };
        await apiSaveSaleCallRecord(saveData);
      } catch (err) {
        let msg = err.msg ? err.msg : "保存通话记录发生异常！";
        this.msgError(msg);
        console.error("apiSaveSaleCallRecord err: ", err);
      }
    },
    /* 初始化socket */
    initSocket() {
      //const url = 'http://new.1.test.manage.bjsihao.cn/'
      //const url = "http://101.200.212.33:9092"
      //const url = "http://localhost:3005/";
      //const url = "http://localhost:3008"
      //const url = "http://dev.socket.liusandao.top"
      //const url = "http://39.98.157.218:3008"
      const token = sessionStorage.getItem("Authorization");
      const userId = sessionStorage.getItem("userId");
      if (!token || token == "null") {
        return;
      }
      const socket = io(socketUrl, {
        path: `/${pathName}`,
        cors_allowed_origins: [],
        query: {
          token,
          userId,
        },
      });
      this.socket = socket;
      socket.on("connect", () => {
        console.log(`my connect: ${socket.connected}`); // true
      });

      socket.on("disconnect", () => {
        console.log(`my disconnect: ${socket.connected}`); // false
      });

      socket.on("data", (e) => {
        console.log("data: ", e);
      });

      socket.on("connect_error", (e) => {
        console.log("my connect_error： ", e);
        //socket.connect();
        if (e.message == "Authentication failed.") {
          this.msgError("socket权限校验失败！");
        } else {
          // this.msgError("socket链接失败！");
        }
      });
      socket.on("parttimeAddUse", (e) => {
        console.log("parttimeAddUse: ", e);
        this.newAddUseNum = e.total;
      });
      socket.on("sysCallStatusUpdateToClient", (e) => {
        console.log("sysCallStatusUpdateToClient: ", e);
        this.sysStatusChange(e);
      });
      socket.on("sysCallStartTipToClient", (e) => {
        console.log("sysCallStartTipToClient: ", e);
        this.sysStatusChange(e);
      });
      socket.on("sysCallEndTipToClient", (e) => {
        console.log("sysCallEndTipToClient: ", e);
        this.sysStatusChange(e);
      });
      socket.on("newHighSeasNumTipTpClient", (e) => {
        if(e.userId != sessionStorage.getItem('userId') && this.saleType == 1){
          this.getNewHighSeasNum()
        }
      })
    },
    /* 退出socket */
    handleLogoutSocket() {
      if (this.socket) {
        this.socket.disconnect();
        this.socket = null;
      }
    },
    /* 修改系统打电话信息 */
    handleSysCallStatusChange(data) {
      if (this.socket) {
        console.log("执行 sysCallStatusUpdateToServe");
        this.socket.emit("sysCallStatusUpdateToServe", data);
      }
    },
    /* 开启定时检测是否到达结束拨号时间 */
    handleStartCheckSysCallStatus() {
      this.handleStopCheckSysCallStatus();
      this._sysCallIntervalTimer = setInterval(() => {
        this.handleSysCallStatus();
      }, 1000);
    },
    /* 关闭定时检测是否到达结束拨号时间 */
    handleStopCheckSysCallStatus() {
      clearInterval(this._sysCallIntervalTimer);
    },
    /* 处理系统拨打时间改变及拨打开关的状态改变 */
    handleSysCallStatus() {
      const { startTime, endTime, status } = this.sysStatus;
      let flagOn = true,
        causeTxt = null;
      const curDate = dayjs().format("YYYY-MM-DD");
      if (status == 2) {
        flagOn = false;
        causeTxt = "系统电话功能已关闭！";
      } else if (startTime && endTime) {
        if (dayjs().isBefore(dayjs(`${curDate} ${startTime}`))) {
          flagOn = false;
          causeTxt = "还未到系统开始拨打时间！";
        } else if (dayjs().isAfter(dayjs(`${curDate} ${endTime}`))) {
          console.log(2222222);
          flagOn = false;
          causeTxt = "已到系统结束拨打时间！";
        }
      }
      this.sysCallInfo.flagOn = flagOn;
      this.sysCallInfo.causeTxt = causeTxt;
    },
    /* 系统不能打电话，尝试退出登录 */
    handleTryLogout() {
      if (this.sysCallInfo.flagOn) {
        return;
      }
      if (!this.callInfo.isCallLogin) {
        return;
      }
      if (
        this.callInfo.callStatus == 1 ||
        this.callInfo.callStatus == 2 ||
        this.callInfo.callStatus == 3 ||
        this.callInfo.callStatus == 5 ||
        this.callInfo.callStatus == 6 ||
        this.callInfo.callStatus == 7
      ) {
        return;
      }
      if (
        this.callInfo.handCallStatus == 1 ||
        this.callInfo.handCallStatus == 2 ||
        this.callInfo.handCallStatus == 3 ||
        this.callInfo.handCallStatus == 5 ||
        this.callInfo.handCallStatus == 6 ||
        this.callInfo.handCallStatus == 7
      ) {
        return;
      }
      this.handleUserLogout();
    },
    /* 电话icon点击 */
    handleCallIconClick() {
      if (!this.dialogCallInfo.show) {
        this.dialogCallInfoChange({
          show: true,
          position: {
            left: "100px",
            top: "100px",
          },
        });
      }
    },
    /* 启动计时 */
    handleContinueTimeCompute() {
      clearInterval(this._continueTimeTimer);
      this._continueTimeTimer = setInterval(() => {
        if (this.callInfo.isCallLogin) {
          if (this.callInfo.isBusy) {
            this.callInfo._continueOffTime++;
            this.callInfo.continueOffTime = getTimeTotal(
              this.callInfo._continueOffTime,
              2
            );
          } else {
            this.callInfo._continueOnTime++;
            this.callInfo.continueOnTime = getTimeTotal(this.callInfo._continueOnTime, 2);
          }
        }
      }, 1000);
    },
    /* 初始化数据 */
    initData() {
      const authorities = sessionStorage.getItem("authorities");
      if (authorities) {
        this.updateAuthorities(JSON.parse(authorities));
      }
      const { token, Host, WSPort, agentHost, agentPort } = getCallData();
      this.callInfo.token = token;
      this.callInfo.Host = Host;
      this.callInfo.WSPort = WSPort;
      this.callInfo.agentHost = agentHost;
      this.callInfo.agentPort = agentPort;
      if (this.saleType) {
        // saleType  1 销售专员 2 销售兼职
        // 刷新保持页面的电话的暂停或者运行状态
        window.addEventListener('beforeunload', () => {
          sessionStorage.setItem("_lastHandBusyType", this.callInfo._lastHandBusyType);
        })
        if (this.callInfo.dkyAgentAccount && sessionStorage.getItem("useCallPlatform") == 1) {
          this.handleUserLogin();
        }
      }
    },
    /* 显示警告信息 */
    showConfirmInfo(msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: "我知道了",
        showCancelButton: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        type: "warning",
      })
        .then(() => {})
        .catch(() => {});
    },
    /* 电话框中位置提示 自定义消息提示 聊天消息提示 */
    handleMsgInfoCallBox(msg) {
      const dom = document.querySelector(".call-box-area");
      if (dom) {
        this.msgSuccess({
          message: msg,
          customClass: this.navIsOpen ? "msg-in-box-2" : "msg-in-box-1",
        });
      } else {
        this.msgSuccess(msg);
      }
    },
    /* 坐席登录 */
    handleUserLogin() {
      const callback = (type) => {
        var event = JSON.parse(type.data); //返回的type为json类型，所以这里进行一下转换操作
        console.log("回调事件打印", event.eventType, type, event);
        switch (event.eventType) {
          case "Heartbeat":
            console.log("前端心跳");
            break;
          case "AgentLogin":
            this.handleSaveCallData(event)
            agentState(event).then((res) => {
              this.handleSaveCallData({
                eventType: "AgentLogin-agentState",
                ...res,
              })
              console.log("agentState: ", res);
              if (res.onlineflag && res.onlineflag == "on") {
                console.log("分机登录");
                DKY_exten_login(
                  this.callInfo.dkyOrganId,
                  this.callInfo.dkyDeviceAccount,
                  this.callInfo.dkyDevicePassword,
                  this.callInfo.Host,
                  this.callInfo.WSPort
                ).then((res) => {
                  switch (res.EventName) {
                    case "SIP_registered":
                      // 分机登录成功
                      this.callInfo.isCallLogin = true;
                      // 获取最新刷新页面之前的状态，保证刷新后还是之前的开启暂停状态一致
                      const _lastHandBusyType = sessionStorage.getItem(
                        "_lastHandBusyType"
                      );
                      // 重置状态为默认值(默认进来是未开启状态)
                      sessionStorage.setItem(
                        "_lastHandBusyType",
                        defaultLastHandBusyType
                      );
                      // 登录成功默认设置为暂停状态
                      if (_lastHandBusyType == 1 || _lastHandBusyType == null) {
                        this.handleBusyOrFreeForHand(1, true);
                      } else {
                        this.handleBusyOrFreeForHand(0, true);
                      }
                      // 不是刷新情况下群呼队列如果不填写类别自动暂停情况处理
                      if (this.saleType == 2) {
                        setTimeout(() => {
                          this.handleBusyOrFreeForAuto(this.callInfo._lastAutoBusyType);
                        }, 1000);
                      }
                      break;
                    case "SIP_unregistered":
                      this.callInfo.isCallLogin = false;
                      break;
                    case "SIP_unregistering":
                      this.callInfo.isCallLogin = false;
                      break;
                    case "SIP_registrationFailed":
                      this.callInfo.isCallLogin = false;
                      this.msgError(res.Info);
                      break;
                  }
                  console.log("分机登录的信息：", res);
                });
              } else if (res.onlineflag && res.onlineflag == "off") {
                if (event.code == "401") {
                  this.$notify.error({
                    title: "发生错误",
                    message: "账号被挤掉，下线，请重新登录！",
                    duration: 0,
                  });
                  setTimeout(() => {
                    this.$router.replace({
                      path: "/login",
                    });
                  }, 2000);
                }
              }
            });
            console.log("前端座席和队列登录");
            break;
          case "AgentState":
            console.log("前端座席状态");
            this.handleSaveCallData(event);
            if (event.code == 200) {
              if (event.state == 0) {
                console.log("回调-置闲成功！");
              } else if (event.state == 1) {
                console.log("回调-置忙成功！");
              }
              this.callInfo.isBusy = event.state == 1 ? true : false;
            }
            break;
          case "MakeCall":
            console.log("前端拨打");
            this.handleSaveCallData(event);
            if (event.code == 200) {
              this.callInfo.linkedid = event.data.linkedid;
              this.callInfo.handCallLinkedid = event.data.linkedid;
            } else {
              this.handleStopTimeTimer();
              DKY_CallState(0);
              // code 26 message 外呼号码触发风控
              if (event.code == "999") {
                console.log("空号！");
              }
              this.callInfo.handCallStatus = 0;
              // 外呼号码为黑名单号码
              if (event.message && event.message.includes("黑名单")) {
                console.log("外呼号码为黑名单号码");
                this.callInfo.isHandCallBlack = true;
                this.handleCallSysAddBlack();
              } else {
                // 除了黑名单不提示，别的都提示
                this.msgWarn(event.message);
              }
              if (event.message && event.message.includes("空号")) {
                console.log("空号");
                this.callInfo.handCallStatus = 9;
              }
              /*SDK内部会自动判断座席是呼出还是呼入，如果是座席呼出，SDK会调整内部状态进行座席自动接听
              ，自动接听后会再将内部状态调整回待接听状态，如果出现座席呼出失败的情况，因为现在socket回调都是
              在使用者哪里处理，SDK内部无法做到失败拦截，导致在呼出失败时内部状态无法调整至待接听状态，如果正好呼出失败后
              第二通电话是客户侧呼入就会导致座席自动接听该呼出电话，因此需要使用者在接到socket的拨打事件回调为失败时，执行该固定函数，
              传递参数值为固定的--0，将SDK内部状态调整回待接听，避免出现呼入自动接听的情况*/
            }
            // 前端拨打保存记录
            this.handleSaveCallRecord();
            break;
          case "DialStateEvent":
            this.handleSaveCallData(event);
            if (event.callerRole == "agent") {
              console.log("前端座席响铃");
              this.callInfo.studentId = event.originate_userfield
                ? event.originate_userfield.split("_")[1]
                : null;
              if (event.originate_xfer_tela) {
                if (event.originate_xfer_tela == this.callInfo.dkyDeviceAccount) {
                  this.callInfo.callStatus = 5;
                  this.callInfo.callType = 2;
                  this.callInfo.linkedid = event.linkedId;
                  this.callInfo.transforLinkedid = event.linkedId;
                  this.handleSaveAutoCallRecord(this.callInfo.transforLinkedid, 6);
                } else {
                  if (this.saleType == 2) {
                    console.log("被转接端坐席已响铃！");
                    this.msgSuccess("销售端已响铃！");
                  }
                }
                // 转接手动接听
                /* DKY_Answer().then((res) => {
                  console.log(res);
                }); */
              } else {
                this.callInfo.linkedid = event.linkedId;
                this.callInfo.autoCallLinkedid = event.linkedId;
                this.handleSaveAutoCallRecord(this.callInfo.autoCallLinkedid, 5);
                this.callInfo.callStatus = 5;
                this.callInfo.callType = 1;
                // 重置转接状态
                this._changeOverFlag = false;
                this.callInfo.changeCallStatus = 0;
                this.callInfo.blindferCallStatus = 0;
                // 预测外呼手动接听
                /* DKY_Answer().then((res) => {
                  console.log(res);
                }); */
                // 转接情况标识
              }
            } else if (event.callerRole == "phone") {
              // 只有手动拨打
              this.callInfo.handCallStatus = 2;
              this.handleStartTimeTimer();
              console.log("前端客户响铃");
            }
            break;
          case "BridgeEnterEvent":
            this.handleSaveCallData(event);
            if (event.callerRole == "agent") {
              // 转接情况标识
              if (event.originate_xfer_tela) {
                // 自己接听
                if (event.originate_xfer_tela == this.callInfo.dkyDeviceAccount) {
                  console.log("前端座席接通-接听转接");
                  if (this.callInfo.handCallLinkedid == event.linkedId) {
                    this.callInfo.handCallStatus = 3;
                  } else {
                    this.callInfo.callStatus = 3;
                  }
                  this.handleStartTimeTimer();
                  // 被转接的人接听  转接响铃
                }
                if (event.is_xfer_event) {
                  console.log("前端座席接通-转接接通");
                  this.callInfo.changeCallStatus = 2;
                } else {
                  if (event.connectedLineName == "devive") {
                    console.log("前端座席接通-转接中-接客户端");
                  } else {
                    console.log("前端座席接通-转接中-接被转接者");
                  }
                  //this.handleMsgInfoCallBox("销售已响铃，程序将自动挂断！");

                  /* this.msgSuccess("销售端已接通，程序将自动挂断！")
                  this.handleDKYHangup();
                  this.handleStopTimeTimer();
                  this.callInfo.changeCallStatus = 2; */
                }
              } else {
                console.log("前端座席接通");
                if (this.callInfo.handCallLinkedid == event.linkedId) {
                  this.callInfo.handCallStatus = 3;
                } else {
                  this.callInfo.callStatus = 3;
                }
                this.handleStartTimeTimer();
              }
            } else if (event.callerRole == "phone") {
              console.log("前端客户接通");
            }
            break;
          case "MusicOnHold":
            if (event.mode == "play") {
              console.log("呼叫暂停，播放音乐");
              this.callInfo.isPause = true;
            } else if (event.mode == "stopplay") {
              console.log("呼叫恢复，继续通话");
              this.callInfo.isPause = false;
            }
            break;
          case "HangupEvent":
            this.handleSaveCallData(event);
            if (event.callerRole == "agent") {
              const dealSelfHandup = () => {
                if (this.callInfo.handCallLinkedid == event.linkedId) {
                  this.callInfo.handCallStatus = 4;
                } else {
                  this.callInfo.callStatus = 4;
                }
                this.handleStopTimeTimer();
                // 手动拨打挂断处理
                if (this.callInfo.handCallLinkedid == event.linkedId) {
                  let answer_status_code = event.answer_status_code;
                  // 4 通话已结束 8 坐席挂断(很快直接挂断了) 9 用户挂断 10 用户未接 11 已挂断（可能是号码拨打完情况）
                  if (answer_status_code == 1) {
                    this.callInfo.handCallStatus = 4;
                  } else if (answer_status_code == 2) {
                    if(this._isHandHangUp){
                      this.callInfo.handCallStatus = 8;
                    }else{
                      this.callInfo.handCallStatus = 11
                    }
                  } else if (answer_status_code == 3) {
                    this.callInfo.handCallStatus = 9;
                  } else if (answer_status_code == 4) {
                    this.callInfo.handCallStatus = 10;
                  } else {
                    // 其他情况
                    this.callInfo.handCallStatus = 9;
                  }
                }
              };
              if (event.originate_xfer_tela) {
                if (event.is_xfer_event) {
                  if (
                    (this.callInfo.changeCallStatus == 1 ||
                      this.callInfo.changeCallStatus == 2) &&
                    this.callInfo.handCallStatus == 3
                  ) {
                    this.msgWarn("转接给的销售专员已挂断电话！");
                  }
                  console.log("前端座席挂断，被转接者挂断电话！");
                  this.callInfo.changeCallStatus = 4;
                } else {
                  if (event.callerIdNum == event.originate_xfer_tela) {
                    // 被转接者，停止响铃 转接者取消转接
                    DKY_StopRing();
                    this.callInfo.changeCallStatus = 4;
                    console.log("前端座席挂断，被转接者挂断！");
                  } else {
                    console.log("前端座席挂断，转接给别人自己挂断！");
                  }
                  // 自己挂断
                  // 如果是非拨打状态尝试退出
                  dealSelfHandup();
                  this.handleTryLogout();
                }
              } else {
                console.log("前端座席挂断，没有转接情况挂断！");
                // 自己挂断
                // 如果是非拨打状态尝试退出
                dealSelfHandup();
                this.handleTryLogout();
              }
            } else if (event.callerRole == "phone") {
              if (event.originate_xfer_tela) {
                console.log("转接-前端客户挂断！");
              } else {
                console.log("非转接-前端客户挂断");
              }

              DKY_StopRing(); //现在事件监听执行交由使用者进行监听，呼入时需要监听一下客户端挂断事件，执行该方法停止座席响铃
              if (event.cause == 47) {
                this.msgError("号码已打完，不能再拨打了！");
              }
            }
            break;
          case "AgentInterface":
            this.handleSaveCallData(event);
            if (event.code == 200) {
              // 转接
              if (event.type == "atxfer") {
                this.callInfo.changeCallStatus = 1;
              } else if (event.type == "atxferHangup") {
                // 取消转接
                this.callInfo.changeCallStatus = 0;
              } else if (event.type == "blindfer") {
                this.handleMsgInfoCallBox("转接成功！");
                this.callInfo.blindferCallStatus = 2;
              }
            } else {
              // 999 频道未建立 1412 转接坐席未登录 不可转接
              if (event.code == 999) {
                this.msgWarn("频道还未建立，等稍后操作！");
              } else {
                this.msgWarn(event.message);
              }
              if (event.type == "atxfer") {
                this.callInfo.changeCallStatus = 3;
              } else if (event.type == "blindfer") {
                this.callInfo.blindferCallStatus = 3;
              }
            }
            break;
          case "AgentStateEvent":
            this.handleSaveCallData(event);
            if (event.state == 1) {
              //this.handleBusyOrFreeForHand(0)
              this.callInfo.isBusy = 0;
              this.callInfo._lastAutoBusyType = 0;
              this.callInfo._lastHandBusyType = 0;
            } else if (event.state == 2) {
              this.callInfo.isBusy = 1;
              this.callInfo._lastHandBusyType = 1;
              //this.handleBusyOrFreeForHand(1)
            } else if (event.state == -1) {
              this.dkyAgentType = 2;
              this.msgWarn("当前坐席被禁用！");
              sessionStorage.setItem("dkyAgentType", this.dkyAgentType);
              this.handleUserLogout();
            }
            break;
        }
      };
      const closecallback = (event) => {
        console.log("前端关闭回调", event);
        switch (event.code) {
          case 1006:
            this.msgError("网络连接失败");
            this.$notify.error({
              title: "错误",
              message: "网络连接失败！",
            });
            break;
        }
        OnClose(event).then((res) => {
          console.log("下线信息", res);
          this.handleSaveCallData({
            eventType: "LoginOutEvent",
            ...res,
          })
          if (res.onlineflag == "off") {
            //this.msgError('电话服务下线！')
            //this.callInfo.isCallLogin = false
          }
        }); //因为SDK内部有一些状态需要修改改，而具体回调函数给与使用进行操作，所以需要固定位置执行一个固定函数
      };
      const errorcallback = (event) => {
        console.log("前端错误回调", event);
        this.msgError("电话服务发生了未知异常！");
        this.handleSaveCallData({
          eventType: "ErrorCallBack",
          data: JSON.stringify(event),
        })
      };
      console.log(
        "DKY_DOLogin",
        this.callInfo.agentHost,
        this.callInfo.agentPort,
        this.callInfo.dkyAgentAccount,
        this.callInfo.dkyDeviceAccount,
        this.callInfo.dkyOrganId,
        this.callInfo.token,
        this.callInfo.queue
      );
      DKY_DOLogin(
        this.callInfo.agentHost,
        this.callInfo.agentPort,
        this.callInfo.dkyAgentAccount,
        this.callInfo.dkyDeviceAccount,
        this.callInfo.dkyOrganId,
        this.callInfo.token,
        this.callInfo.queue,
        callback,
        closecallback,
        errorcallback
      ).then((res) => {
        console.log("座席登录", res);
        this.handleSaveCallData({
          eventType: "LoginEvent",
          ...res,
        })
        if (res.onlineflag == "on") {
          console.log("坐席登录成功！");
        }
        if (res.onlineflag == "off") {
          this.$notify.error({
            title: "错误",
            message: `电话${res.Info}，检查后刷新重试！`,
            duration: 0,
          });
        }
        if (res.EventName == "Media_error") {
          this.$notify.error({
            title: "错误",
            message: `${res.Info}，坐席登录失败，检查后刷新重试！`,
            duration: 0,
          });
        } else if (res.EventName == "Login_exception") {
          this.$notify.error({
            title: "错误",
            message: `电话${res.Info}，检查后刷新重试！`,
            duration: 0,
          });
        }
      });
    },
    /* 坐席登出 */
    handleUserLogout() {
      if (!this.saleType) {
        return;
      }
      DKY_un_exten_login().then((res) => {
        console.log("分机退出：", res);
      });
      DKY_unDoLogin().then((res) => {
        this.handleSaveCallData({
          eventType: "LoginOutEvent",
          ...res,
        })
        if (res.onlineflag == "off") {
          this.callInfo.isCallLogin = false;
        }
        console.log("座席退出: ", res);
      });
    },
    /* 分机外呼 | 手动拨打 */
    /* handCallType 1 手拨列表(首拨) 2 手拨列表(群呼) 3 我的意向列表 4 兼职意向 5 兼职预测外呼 6 专员接收的转接 7 销售专员我的客户资源 */
    async handleHandCall(callednum, studentId, handCallType) {
      if (sessionStorage.getItem("useCallPlatform") == 0) {
        this.msgWarn("当前用户没有分配电话服务，不能拨打电话！");
        return;
      }
      if (!this.callInfo.isAllocDkyAccount) {
        this.msgWarn("当前用户没有分配坐席，不能拨打电话！");
        return;
      }
      if (this._callTimeout) {
        this.msgWarn("不要频繁点击，请稍后再点击！");
        return;
      }
      this._callTimeout = setTimeout(() => {
        this._callTimeout = null;
      }, 1000);
      if (this.dkyAgentType == 2) {
        this.msgWarn("坐席电话功能已被禁用！");
        return;
      }
      if (!this.sysCallInfo.flagOn) {
        this.msgWarn(this.sysCallInfo.causeTxt);
        return;
      }
      if (
        this.callInfo.callStatus == 1 ||
        this.callInfo.callStatus == 2 ||
        this.callInfo.callStatus == 3 ||
        this.callInfo.callStatus == 5 ||
        this.callInfo.callStatus == 6 ||
        this.callInfo.callStatus == 7
      ) {
        return this.msgWarn("正在通话中，请稍后再操作！");
      }
      if (
        this.callInfo.handCallStatus == 1 ||
        this.callInfo.handCallStatus == 2 ||
        this.callInfo.handCallStatus == 3 ||
        this.callInfo.handCallStatus == 5 ||
        this.callInfo.handCallStatus == 6 ||
        this.callInfo.handCallStatus == 7
      ) {
        return this.msgWarn("正在通话中，请稍后再操作！");
      }
      this.callInfo.isFromHandCall = true;
      this.callInfo.handCallType = handCallType;
      this.callInfo.handCallStatus = 7;
      this.callInfo._isHandHangUp = false;
      this.callInfo._handCallStudentId = studentId;
      this.callInfo.isHandCallBlack = false;
      this.callInfo.changeCallStatus = 0;
      this.callInfo.blindferCallStatus = 0;
      let userfield = dealHandCallUserfield(studentId);
      /* if(envType == 'dev'){
        this.handleSaveCallRecord();
        this.callInfo.handCallStatus = 0
        return
      } */
      DKY_MakeCALL({
        callednum,
        telX: "",
        userfield,
      }).then((res) => {
        console.log("外呼：", res);
        this.handleSaveCallData({
          eventType: "MakeCallClickEvent",
          ...res,
        });
        if (res.state != "outgoing") {
          if (res.onlineflag == "off") {
            this.msgWarn(`${res.Info}，请刷新浏览器重试！`);
          } else {
            this.msgWarn(res.Info);
          }
          this.callInfo.handCallStatus = 0;
          return;
        }
        this.callInfo.changeCallStatus = 0;
        this.callInfo.callType = 3;
        this.callInfo.handCallStatus = 1;
        // 呼叫中计时
        this.handleStartTimeTimer();
      });
    },
    /* 暂停/恢复 */
    handlePauseOrRestore() {
      if (this.callInfo.callStatus != 3) {
        return;
      }
      if (this.callInfo.isPause) {
        DKY_HoldCall({
          mode: "stopplay",
          linkedId: this.callInfo.linkedid,
        });
        console.log("stopplay");
      } else {
        DKY_HoldCall({
          mode: "play",
          linkedId: this.callInfo.linkedid,
        });
        console.log("play");
      }
    },
    /* 开启计时 */
    handleStartTimeTimer() {
      this.handleStopTimeTimer();
      let s = 0;
      if (this.callInfo.callType == 1 || this.callInfo.callType == 2) {
        this.callInfo.duration = "00:00:00";
      } else if (this.callInfo.callType == 3) {
        this.callInfo.handDuration = "00:00:00";
      }
      this._timeTimer = setInterval(() => {
        s += 1000;
        let duration = dayjs.duration(s).format("HH:mm:ss");
        if (this.callInfo.callType == 1 || this.callInfo.callType == 2) {
          this.callInfo.duration = duration;
        } else if (this.callInfo.callType == 3) {
          this.callInfo.handDuration = duration;
        }
        console.log(" this.callInfo.duration: ", duration);
      }, 1000);
    },
    /* 关闭计时 */
    handleStopTimeTimer() {
      clearInterval(this._timeTimer);
    },
    /* 静音/取消静音 */
    handleMuteOrUnMute() {
      if (this.callInfo.callStatus != 3) {
        return;
      }
      if (this.callInfo.isMute) {
        DKY_Mute("stop").then((res) => {
          console.log("座席侧取消静音", res);
          this.callInfo.isMute = !this.callInfo.isMute;
        });
      } else {
        DKY_Mute("start").then((res) => {
          console.log("座席侧静音", res);
          this.callInfo.isMute = !this.callInfo.isMute;
        });
      }
    },
    /* 置闲/置忙 (手动点击) */
    handleBusyOrFreeForHand(type, flag) {
      this.callInfo._lastHandBusyType = type;
      // flag 是否是首次加载
      if (flag) {
        if (type == 0) {
          console.log("手动置闲");
          DKY_Pauses(type, "").then((res) => {
            console.log(`${type == 0 ? "置闲" : "置忙"}`, res); //如果置忙成功，then函数不会被触发
          });
        } else if (type == 1) {
          console.log("手动置忙");
          DKY_Pauses(type, "").then((res) => {
            console.log(`${type == 0 ? "置闲" : "置忙"}`, res); //如果置忙成功，then函数不会被触发
          });
        }
      } else {
        if (type == 0) {
          if (this.callInfo.isBusy && type == this.callInfo._lastAutoBusyType) {
            console.log("手动置闲");
            DKY_Pauses(type, "").then((res) => {
              console.log(`${type == 0 ? "置闲" : "置忙"}`, res); //如果置忙成功，then函数不会被触发
            });
          }
        } else if (type == 1) {
          if (!this.callInfo.isBusy) {
            console.log("手动置忙");
            DKY_Pauses(type, "").then((res) => {
              console.log(`${type == 0 ? "置闲" : "置忙"}`, res); //如果置忙成功，then函数不会被触发
            });
          }
        }
      }
    },
    /* 置闲/置忙（根据接听电话信息程序自动判断） */
    handleBusyOrFreeForAuto(type) {
      this.callInfo._lastAutoBusyType = type;
      if (type == 0) {
        if (this.callInfo.isBusy && type == this.callInfo._lastHandBusyType) {
          console.log("客户信息自动置闲");
          DKY_Pauses(type, "").then((res) => {
            console.log(`${type == 0 ? "置闲" : "置忙"}`, res); //如果置忙成功，then函数不会被触发
          });
        }
      } else if (type == 1) {
        if (!this.callInfo.isBusy) {
          console.log("客户信息自动置忙");
          DKY_Pauses(type, "").then((res) => {
            console.log(`${type == 0 ? "置闲" : "置忙"}`, res); //如果置忙成功，then函数不会被触发
          });
        }
      }
    },
    /* 挂断 */
    handleDKYHangup() {
      if (this._hangupTimeout) {
        this.msgWarn("请稍等再操作！");
        return;
      }
      this._hangupTimeout = setTimeout(() => {
        this._hangupTimeout = null;
      }, 1000);
      if (this.callInfo.callType == 3) {
        this.callInfo._isHandHangUp = true;
      }
      console.log("执行了 DKY_HangUp");
      try {
        DKY_HangUp();
        this.handleSaveCallData({
          eventType: "HandUpClickEvent",
        });
      } catch (err) {
        this.handleSaveCallData({
          eventType: "HandUpClickErrEvent",
        });
        console.log("HandUpClickErrEvent : ", err);
      }
    },
    /* 转接情况未接听挂断 */
    handleDKYHangupAll() {
      if (this._hangupTimeout) {
        this.msgWarn("请稍等再操作！");
        return;
      }
      this._hangupTimeout = setTimeout(() => {
        this._hangupTimeout = null;
      }, 1000);
      if (this.callInfo.callType == 3) {
        this.callInfo._isHandHangUp = true;
      }
      console.log("转接挂断, this.callInfo.linkedid: ", this.callInfo.linkedid);
      this.handleSaveCallData({
        eventType: "HandUpClickEvent",
      });
      DKY_HangupCall({
        linkedId: this.callInfo.linkedid,
      });
    },
    /* 接听 */
    handleDKYAnswer() {
      this.callInfo.callStatus = 6;
      this.handleSaveCallData({
        eventType: 'AnswerClickEvent'
      })
      DKY_Answer().then((res) => {
        console.log(res);
        /* this.handleSaveCallData({
          eventType: 'AnswerClickResEvent',
          ...res
        }) */
      });
    },
    /* 盲转 */
    handleDKYBindfer(ext2) {
      const data = {
        ext2,
        mode: 0,
        linkedId: this.callInfo.linkedid,
      };
      console.log("盲转：", data);
      this.callInfo.blindferCallStatus = 1;
      DKY_Blindfer(data);
    },
    /* 转接 */
    handleDKYChangeOver(ext2) {
      if (this._changeOverFlag) {
        this.msgWarn("请稍等再操作！");
        return;
      }
      this._changeOverFlag = true;
      setTimeout(() => {
        this._changeOverFlag = false;
      }, 1000);
      this.callInfo.ext2 = ext2;
      const data = {
        ext2,
        linkedId: this.callInfo.linkedid,
      };
      console.log("转接");
      DKY_ChangeOver(data);
    },
    /* 转接挂断 */
    handleDKYChangeOverHangUp() {
      DKY_ChangeOverHangup({
        linkedId: this.callInfo.linkedid,
      });
      console.log("取消转接 this.callInfo.linkedid： ", this.callInfo.linkedid);
    },
    /* 兼职加入有用 */
    handleParttimeAddUse(data) {
      if (this.socket) {
        this.socket.emit("parttimeAddUse", data);
      }
    },
    /* 释放数据到公海 */
    handleReleaseToHighSeas() {
      if(this.socket) {
        this.socket.emit("highSeasNumAdd", {userId: sessionStorage.getItem("userId")})
      }
    },
    /* 销售专员查看兼职加入的有用 */
    handleSaleStaffSeeUse() {
      if (this.newAddUseNum == 0) {
        return;
      }
      if (this.socket) {
        this.newAddUseNum = 0;
        this.socket.emit("saleSeeUse");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  position: relative;
  .call-short {
    position: fixed;
    left: 20px;
    bottom: 20px;
    z-index: 99999;
    .call-img {
      cursor: pointer;
      width: 30px;
      transition: transform 1s;
      &:hover {
        transform: scale(1.4);
      }
    }
  }
  .left {
    position: relative;
    z-index: 20;
  }
  .right {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    .main {
      flex: 1;
      overflow-y: hidden;
      overflow-x: hidden;
      min-width: 1160px;
      .page-area {
        height: 100%;
        overflow-x: auto;
        position: relative;
      }
    }
  }
}
</style>
